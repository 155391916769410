import type { User } from 'typedefs/User';

/** Utility methods for autocompletion. */
export class AutoCompletionUtils {
	/** Returns the full names of the user, e.g. Max Mustermann [mustermann] */
	public static getUserFullName(user: User): string {
		return user.firstName + ' ' + user.lastName + ' [' + user.username + ']';
	}

	/**
	 * Returns the user name from a full name as created by getUsersFullNames(). If the format does not match, the
	 * supplied name is returned.
	 *
	 * @param fullName The user's full name, e.g. Max Mustermann [mustermann]
	 */
	public static getUserNameFromFullName(fullName: string): string {
		const match = fullName.match(/\[(.*?)]/);
		return match?.[1] ?? fullName;
	}
}
